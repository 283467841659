import Hamburger from "./components/Hamburger";
// import { WaitingPage } from "./components/WaitingPage";
// import { Registry } from "components/Registry";
// import { Information } from "components/Information";
// import { Response } from "components/Response";
import { Four0Four } from "components/Error";
// import { ThankYou } from "components/ThankYou";
// import { Admin } from "components/Admin";
// import { Invitation } from "components/Invitation";
// import { ImportantMessage } from "components/ImportantMessage";
import { Routes, Route } from "react-router-dom";
// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";

import "./App.scss";
// import { Tables, Tables2 } from "components/Tables";
import { GoodBye } from "components/GoodBye";

// const firebaseConfig = {
//   apiKey: "AIzaSyCr-Da4PEJAd7Xn45ZKUhm7S7xlO3ue5FM",
//   authDomain: "wedding-8dcb9.firebaseapp.com",
//   // For databases not in the us-central1 location, databaseURL will be of the
//   // form
//   databaseURL: "https://wedding-8dcb9.europe-west1.firebasedatabase.app",
//   storageBucket: "bucket.appspot.com",
// };

function App() {
  // const app = initializeApp(firebaseConfig);
  // const database = getDatabase(app);

  return (
    <div className="App">
      <Hamburger />
      <Routes>
        <Route path="/" exact element={<GoodBye />} />
        {/* <Route path="/" exact element={<WaitingPage />} /> */}
        {/* <Route path="/gaveliste" element={<Registry />} />
        <Route path="/svarskjema" element={<Response />} />
        <Route path="/praktisk-informasjon" element={<Information />} />
        <Route path="/takk-for-ditt-svar" element={<ThankYou />} />
        <Route path="/invitasjon" element={<Invitation />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/bordplan" element={<Tables2 />} />
        <Route path="/hemmelig-bordplan" element={<Tables />} />
        <Route path="/viktig-melding" element={<ImportantMessage />} /> */}
        <Route path="*" element={<Four0Four />} />
      </Routes>
    </div>
  );
}

export default App;
