import { Link } from "react-router-dom";
import { useState } from "react";
import classes from "./Hamburger.module.scss";

function Hamburger() {
  // eslint-disable-next-line
  const [hamburgerClass, setHamburgerClass] = useState(classes.hamburger);
  // eslint-disable-next-line
  const [navmenuClass, setNavmenuClass] = useState(classes.navmenu);
  const [open, setOpen] = useState(false);

  function toggleShow() {
    setHamburgerClass(classes.hamburger + " " + classes.active);
    setNavmenuClass(classes.navmenu + " " + classes.active);
    setOpen(true);
  }

  function toggleHide() {
    setHamburgerClass(classes.hamburger);
    setNavmenuClass(classes.navmenu);
    setOpen(false);
  }

  // eslint-disable-next-line
  function toggle() {
    if (!open) {
      toggleShow();
    } else {
      toggleHide();
    }
  }

  return (
    <header>
      <nav className={classes.navbar}>
        <Link className={classes.navbranding} to="/">
          Anine + Kristoffer
        </Link>
        {/* <ul className={navmenuClass}>
          <li className={classes.navitem}>
            <Link to={`/gaveliste`} onClick={() => toggle()}>
              Ønskeliste
            </Link>
          </li>
          <li className={classes.navitem}>
            <Link to={`/svarskjema`} onClick={() => toggle()}>
              Svarskjema
            </Link>
          </li>
          <li className={classes.navitem}>
            <Link to="/praktisk-informasjon" onClick={() => toggle()}>
              Praktisk informasjon
            </Link>
          </li>
          <li className={classes.navitem}>
            <Link to="/invitasjon" onClick={() => toggle()}>
              Invitasjonen
            </Link>
          </li>
        </ul>
        <div className={hamburgerClass} onClick={() => toggle()}>
          <span className={classes.bar}></span>
          <span className={classes.bar}></span>
          <span className={classes.bar}></span>
        </div> */}
      </nav>
    </header>
  );
}

export default Hamburger;
