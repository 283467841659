import classes from "components/Error.module.scss";

export function Four0Four() {
  const url = window.location.pathname;
  return (
    <div className={classes.error}>
      <h1>404</h1>
      Hvis du mener at {url} burde inneholdt noe, setter jeg pris på om du
      sender meg en melding.
    </div>
  );
}
