import classes from "components/GoodBye.module.scss";
import img from "img/gift.jpeg";

export function GoodBye() {
  return (
    <div className={classes.goodBye}>
        <h1 className={classes.thankYou}>Takk for at dere kom!</h1>
        <img src={img} className={classes.pic} alt="brudeparet" />
        <p className={classes.paragraph}>
            Dagen kunne ikke blitt bedre og vi er storfornøyd! 
            <br/><br/>
            Vi har opprettet en Google Disk for deling av bilder, gi oss en pling, så legger vi dere til  &#128248;
        </p>
    </div>
  );
}
